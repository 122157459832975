import React from 'react';

import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { Finish } from '../pages/Finish';
import { Home } from '../pages/Home';

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <>
          <Route exact path="/" component={Home} />
          {/* {/* <Route exact path="/a-tropzz" component={Tropzz} /> */}
          <Route exact path="/finish" component={Finish} />
        </>
        {/* <Route path="" component={Home} /> */}
        {/* <Route exact path="/details/:card" component={Details} /> */}
      </Switch>
    </BrowserRouter>
  );
}
